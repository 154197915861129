.nav {
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(53, 60, 61);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0 auto;
    z-index: 5000;
}

.nav a {
    color: rgb(221, 215, 215);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.nav a:hover {
    background-color: #ddd;
    color: black;
}

.nav .date {
    float: right;
    margin-right: 5px;
    margin-left: auto;
    color: rgb(221, 215, 215);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    user-select: none;
}